import React from 'react';
import './footer.scss';

function Footer() {
    return (
        <div className="local-scss d-none d-lg-block">
            <b>Copyright © 2007-{(new Date().getFullYear())} ITnent</b>
        </div>
    );
}

export default Footer;
