import React from 'react';
import Container from "react-bootstrap/Container";
import "./company-standards.scss";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import {FaGitlab, FaGithub, FaJava, FaJira, IoLogoAngular, IoLogoReact, SiCsharp, SiKotlin} from "react-icons/all";

function CompanyStandards() {

    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-standard-img-company-standards text-color-company-standards">
                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-4">
                    <div><h1><b className="size-title-company-standards custom-word-break">{translation("title.company-standards")}</b></h1></div>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <div className="mb-lg-5 mb-sm-2 custom-word-break size-words-company-standards size-words-mobile-company-standards">
                            {translation("company-standards.left-text")}
                        </div>
                        <div className="border-top-line border-left-line">
                            <div
                                className="custom-size-standards ms-lg-2 mt-sm-2">{translation("company-standards.counters-block")}</div>
                            <div className="ms-lg-2 mb-2">
                                <SiCsharp className="custom-icon-border" size="3rem"/>
                                <FaJava className="custom-icon-border" size="3rem"/>
                                <SiKotlin className="custom-icon-border" size="3rem"/>
                            </div>
                            <div className="ms-lg-2">
                                <IoLogoAngular className="custom-icon-border" size="3rem"/>
                                <IoLogoReact className="custom-icon-border" size="3rem"/>
                            </div>
                            <div className="ms-lg-2 mb-2">
                                <FaGithub className="custom-icon-border" size="3rem"/>
                                <FaGitlab className="custom-icon-border" size="3rem"/>
                                <FaJira className="custom-icon-border" size="3rem"/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} className="mb-sm-2 border-left-line">
                        <div className="custom-size mt-sm-2 border-top-line">
                            <div className="custom-size-standards">{translation("company-standards.standards")}</div>
                            <ul>
                                <li>{translation("company-standards.standardsPointOne")}</li>
                                <li>{translation("company-standards.standardsPointTwo")}</li>
                                <li>{translation("company-standards.standardsPointThree")}</li>
                                <li>{translation("company-standards.standardsPointFour")}</li>
                                <li>{translation("company-standards.standardsPointFive")}</li>
                                <li>{translation("company-standards.standardsPointSix")}</li>
                                <li>{translation("company-standards.standardsPointSeven")}</li>
                            </ul>
                        </div>

                        <div className="custom-size mt-sm-2">
                            {translation("company-standards.review")}
                        </div>

                        <div className="custom-size mt-sm-2">
                            <b>{translation("company-standards.patterns1")}</b> {translation("company-standards.patterns2")}
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    );
}

export default CompanyStandards;
