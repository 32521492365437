import React from 'react';
import './contacts.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Pavel from '../../../image/Pavel.png';
import Dmitry from '../../../image/Dmitry.png'

function Contacts() {

    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-img-contacts text-color-contacts custom-size">
                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-5">
                    <div><h1><b className="size-title-contacts custom-word-break">{translation("title.contact-us")}</b>
                    </h1></div>
                </Row>

                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-5">

                    <Col xs={12} sm={12} md={12} lg={3} className="mt-sm-2">
                        <div>
                            <div className="mt-sm-2">
                                <div><h3>CEO:</h3></div>
                                <div>{translation("description.ceo")}</div>
                            </div>

                            <div className="mt-sm-2">
                                <div className="custom-contacts"><h3>{translation("description.phone.title")}:</h3></div>
                                <div>+7 (917) 330-47-30</div>
                            </div>

                            <div className="mt-sm-2">
                                <div className="custom-contacts"><h4>E-mail:</h4></div>
                                <div><a href='mailto:kudryashov@itnent.com'>kudryashov@itnent.com</a></div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={3} className="mb-sm-2">
                        <div className="mt-sm-2">
                            <div><img className="mt-sm-5 custom-img-contacts" height="210" src={Pavel} alt={"Pavel"}/>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={3} className="mt-sm-2">

                        <div className="mt-sm-2 border-top-line">
                            <div className="custom-contacts"><h3>COO:</h3></div>
                            <div>{translation("description.coo")}</div>
                        </div>

                        <div className="mt-sm-2">
                            <div className="custom-contacts"><h3>{translation("description.phone.title")}:</h3></div>
                            <div>+7 (927) 255-01-56</div>
                        </div>

                        <div className="mt-sm-2">
                            <div className="custom-contacts"><h4>E-mail:</h4></div>
                            <div><a href='mailto:dmitry.dubrovkin@itnent.com'>dmitry.dubrovkin@itnent.com</a></div>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={3} className="mb-sm-2">
                        <div className="mt-sm-2">
                            <div><img className="mt-sm-5 custom-img-contacts" height="210" src={Dmitry} alt={"Dmitry"}/>
                            </div>
                        </div>
                    </Col>


                    <Col xs={12} sm={12} md={12} lg={12} className="mt-sm-2 border-left-line">
                        <div className="mt-sm-2 border-top-line">
                            <Row>
                                <h3>
                                    <div className="custom-contacts">{translation("description.address.title")}</div>
                                </h3>
                            </Row>
                            <Row>
                                <div>{translation("description.address.city")}</div>
                            </Row>
                            <Row className="mt-lg-5 mt-sm-5 custom-height-row-contacts">
                                <div className="h_iframe">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2632.6937126880384!2d44.504093115899174!3d48.71133491900336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x411acb3831483817%3A0x8081bbff544527b!2z0YPQuy4g0J3QvtCy0L7RgNC-0YHRgdC40LnRgdC60LDRjywgMtCRLCDQktC-0LvQs9C-0LPRgNCw0LQsINCS0L7Qu9Cz0L7Qs9GA0LDQtNGB0LrQsNGPINC-0LHQuy4sIDQwMDA4Nw!5e0!3m2!1sru!2sru!4v1634118536559!5m2!1sru!2sru"
                                    />
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    )
        ;
}

export default Contacts;
