import React from 'react';
import Container from "react-bootstrap/Container";
import "./projects.scss";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import {FaAws, FaJava, IoLogoAngular, IoLogoReact, SiCsharp, SiKotlin} from "react-icons/all";

function Projects() {

    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-projects-img-projects text-color-projects">
                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-4">
                    <div className="custom-word-break"><h1><b className="size-title-projects">{translation("title.projects")}</b></h1></div>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={5}>
                        <div className="mb-lg-5 mb-sm-2 size-words-projects size-words-mobile-projects">
                            {translation("projects.left-text")}
                        </div>
                        <div className="border-top-line border-left-line">
                            <div className="custom-size-project ms-lg-2 mt-sm-2">{translation("projects.counters-block")}</div>
                            <div className="ms-lg-2 mb-2">
                                <SiCsharp className="custom-icon-border" size="3rem"/>
                                <FaJava className="custom-icon-border" size="3rem"/>
                                <SiKotlin className="custom-icon-border" size="3rem"/>
                            </div>
                            <div className="ms-lg-2">
                                <IoLogoAngular className="custom-icon-border" size="3rem"/>
                                <IoLogoReact className="custom-icon-border" size="3rem"/>
                            </div>
                            <div className="ms-lg-2 mb-2">
                                <FaAws className="custom-icon-border" size="3rem"/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} className="mb-sm-2 border-left-line">
                        <div className="border-top-line">
                            <div className="custom-size-project mt-sm-2">
                                {translation("projects.specialization")}
                                <ul>
                                    <li>
                                        <b>{translation("projects.specializationPointOne1")} </b>{translation("projects.specializationPointOne2")}
                                    </li>
                                    <li>{translation("projects.specializationPointTwo1")}
                                        <b>{translation("projects.specializationPointTwo2")}</b> {translation("projects.specializationPointTwo3")}
                                    </li>
                                    <li>{translation("projects.specializationPointThree")}</li>
                                    <li>{translation("projects.specializationPointFour")}</li>
                                </ul>
                            </div>

                            <div className="custom-size">
                                <div><b>{translation("projects.customers")}</b></div>
                                <ul>
                                    <li>{translation("projects.customersPointOne")} <a
                                        href='http://www.siemens.com/psa'>www.siemens.com/psa</a></li>
                                    <li>{translation("projects.customersPointTwo")}</li>
                                    <li>{translation("projects.customersPointThree")}</li>
                                    <li>{translation("projects.customersPointFour")}</li>
                                    <li>{translation("projects.customersPointFive")}</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    );
}

export default Projects;
