import React from 'react';
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FaJava, IoLogoAngular, IoLogoReact, SiCplusplus, SiCsharp, SiKotlin} from "react-icons/all";
import "./career.scss"

function Career() {
    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-career-img-career text-color">

                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-4">
                    <div className="custom-word-break"><h1><b className="size-title-career">{translation("title.career")}</b></h1></div>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <div className="mb-lg-5 mb-sm-2 size-words-career size-words-mobile-career">
                            {translation("career.work")}
                        </div>

                        <div className="border-top-line border-left-line">
                            <div className="custom-size-career ms-lg-2 mt-sm-2">{translation("career.look")}</div>

                            <div className="ms-lg-2 mb-2">
                                <FaJava className="custom-icon-border" size="3rem"/>
                                <SiKotlin className="custom-icon-border" size="3rem"/>
                            </div>

                            <div className="ms-lg-2 mb-2">
                                <SiCsharp className="custom-icon-border" size="3rem"/>
                                <SiCplusplus className="custom-icon-border" size="3rem"/>
                            </div>

                            <div className="ms-lg-2">
                                <IoLogoAngular className="custom-icon-border" size="3rem"/>
                                <IoLogoReact className="custom-icon-border" size="3rem"/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} className="mb-sm-2 border-left-line">
                        <div className="border-top-line">
                            <div className="custom-size-career mt-sm-2">
                                {translation("career.unique")} <b>{translation("career.code")}</b>
                            </div>

                            <div className="custom-size mt-sm-2">
                                {translation("career.quality")}
                            </div>

                            <div className="custom-size mt-sm-2">
                                {translation("career.lastSeveralYears")}
                                <ul>
                                    <li>{translation("career.lastSeveralYearsPointOne")}</li>
                                    <li>{translation("career.lastSeveralYearsPointTwo")}</li>
                                    <li>{translation("career.lastSeveralYearsPointThree")}</li>
                                    <li>{translation("career.lastSeveralYearsPointFour")}</li>
                                </ul>
                            </div>

                            <div className="custom-size">
                                {translation("career.offer")}
                            </div>

                            <div className="custom-size">
                                <div><b>{translation("career.lookingForProfessionalsTitle")}</b></div>
                                <ul>
                                    <li>{translation("career.lookingForProfessionalsPointOne")}</li>
                                    <li>{translation("career.lookingForProfessionalsPointTwo")}</li>
                                    <li>{translation("career.lookingForProfessionalsPointThree")}</li>
                                </ul>
                            </div>

                            <div className="custom-size">
                                {translation("career.email")} <a
                                href='mailto:kudryashov@itnent.com'>kudryashov@itnent.com</a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    );
}

export default Career;
