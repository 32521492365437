import React from "react";
import './App.css';
import Header from "./app/header/header";
import Footer from "./app/footer/footer";
import {Route, Switch} from "react-router-dom";
import About from "./app/infrastructure/about/about";
import Project from "./app/infrastructure/projects/projects";
import CompanyStandards from "./app/infrastructure/company-standards/company-standards";
import Career from "./app/infrastructure/career/career";
import Contacts from "./app/infrastructure/contacts/contacts";

function App() {
    return (
        <div className="page-container">
            <div className="container-wrap">
                <Header/>
                <Switch>
                    <Route defaultActiveKey="/" exact path="/" component={About}/>
                    <Route path="/projects" component={Project}/>
                    <Route path="/companyStandards" component={CompanyStandards}/>
                    <Route path="/career" component={Career}/>
                    <Route path="/contacts" component={Contacts}/>
                </Switch>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
